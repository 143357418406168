.navbar-brand a {
  color: #fff;
  text-decoration: none;
}
.navbar-brand a h3 {
  margin-bottom: 0;
  color: #fff;
}
.navbar-expand-lg .navbar-nav a{
  padding: 10px 10px;
  margin: 0 1px;
  text-decoration: none;
  color: #fff;
}

.navbar-expand-lg .navbar-nav a.active,
.navbar-expand-lg .navbar-nav a:focus,
.navbar-expand-lg .navbar-nav a:hover{
  color: #f4c623 ;
  text-decoration: underline;
}
.navbgOnScroll{
  background-color: #043056;
  transition: ease-in ;
}
.navbar-toggler{
  background-color: white;
}
.navbar-toggler-icon{
  color: #fff !important;
}

@media (max-width: 990px) {
  .navbar-collapse{
    background-color: #043056;
  }
}
.navbar-expand-lg .navbar-nav a.btn-success{
  background-color: #055a03 !important;
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2AA4FB !important;
  --bs-btn-border-color: #2AA4FB !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2AA4FB !important;
  --bs-btn-disabled-border-color: #2AA4FB !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--dark, #242424);
  font-family: Times New Roman !important;
  font-style: normal;
  line-height: normal;
  font-weight: 700 !important;
}
.h1,
h1 {
  font-size: 42px !important;
}
.h2,
h2 {
  font-size: 34px !important;
}
.h3,
h3 {
  font-size: 28px !important;
}
.h4,
h4 {
  font-size: 24px !important;
}
.h5,
h5 {
  font-size: 18px !important;
}
.h6,
h6 {
  font-size: 16px !important;
}
.p,
p {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 15px;
  font-weight: 400;
  color: var(--dark, #242424);
  font-style: normal;
  line-height: normal;
}
img{
  width:100%;
  display: block;
}
.bg-light-blue {
  background-color: #e9f5fd;
}
.sectionHeading {
  display: block;
}
.sectionHeading p {
  color: #2aa4fb;
  margin: 0 !important;
}
section {
  padding: 70px 0;
}
.service-item .card {
  background-color: #e9f5fd;
  border: none !important;
  position: relative;
  overflow: hidden;
}
.service-item .card .icon {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 50%;
  padding: 0.7rem;
  color: #2aa4fb;
  font-size: 14px;
  margin-bottom: 5px;
}
.service-item .card:hover .details {
  bottom: 0;
  transition: bottom 0.9s ease;
  display: block;
  -webkit-transition: transform 0.9s ease bottom, opacity 0.9s ease bottom;
}
.service-item .card .details {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  background-color: #043056;
  transition: bottom 0.9s ease;
  -webkit-transition: transform 0.9s ease bottom, opacity 0.9s ease bottom;
}
.service-item .card .details p{
  color: #fff;
}
.service-item .card .details .card-text {
  -webkit-line-clamp: 10;
}
.card-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  align-self: stretch;
  overflow: hidden;
}
.demo-section {
  background: url(../src/img/demo-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.demo-form {
  background-color: #fff;
  padding: 30px !important;
}
/* testimonials section */
.testimonials .card .card-body p {
  font-size: 15px;
  color: #848383;
  margin-top: 10px;
}
.testimonials .card .card-body .card-img {
  background-color: #d9d9d9;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.testimonials .card .card-body .testimonial {
  align-items: center !;
  display: flex;
}
.testimonials .card .card-body .title {
  margin-left: 0.5rem;
}
.testimonials .card .card-body .title h6 {
  margin-bottom: 3px;
}
.testimonials .card .card-body .title p {
  margin: 0px;
}
.testimonials .card .card-body .badge {
  border-radius: 50%;
  padding: 0.65rem 0.75rem;
  position: absolute;
  top: -20px;
}
/* about section */
.aboutRow {
  align-items: center;
}
.aboutRow .col-md-6:nth-child(2) {
  text-align: center;
}
.aboutRow img {
  width: 80%;
  margin: 0 auto;
}
/* carousel slider banner */
.carousel-caption {
  top: 0;
  text-align: left !important;
  padding-top: 5rem !important;
}
.carousel-caption img{
  width: 100%;
}
@media (min-width: 1200px) {
  .carousel-caption h1 {
    font-size: 68px !important;
  }
}
.carousel-caption h1 span {
  color: #f4c623;
}
.carousel-caption .row {
  align-items: center;
}
.carousel .carousel-inner .carousel-item {
  height: 100vh;
  background-color: #043056;
}
.carousel .carousel-inner .carousel-item:nth-child(2) {
  background-color: #9A0808;
}
.carousel .carousel-inner .carousel-item:nth-child(3) {
  background-color: #3b0571;
}
.carousel .carousel-inner .carousel-item:nth-child(4) {
  background-color: #055a03;
}

.carousel .carousel-inner .carousel-item img{
  width: 100%;
}
/* footer */
.footer {
  background: #1c3a6c;
  color: #fff;
}
.footer-links {
  padding-top: 70px;
  padding-bottom: 2rem;
}
.footer p {
  overflow: hidden;
  color: #fff;
  text-align: justify;
  text-overflow: ellipsis;
  font-family: Arial;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  align-self: stretch;
}
.footer ol,
.footer ul {
  padding-left: 1.4rem;
}
.footer ol li,
.footer ul li {
  font-size: 14px;
}
.footer ol li a,
.footer ul li a{
  color: #fff;
  text-decoration: none;
}
.footer ol li:hover,
.footer ul li:hover{
  text-decoration: underline;
}
.footer-copyright {
  background: #0e2c5f;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.footer-copyright p {
  text-align: center;
  margin-bottom: 0;
}
.contactUs {
  position: relative;
  height: 500vh;
  background: url(https://picsum.photos/id/1043/800/600);
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(28, 58, 108, 0.5);
}
.whyus {
  background: url(../src/img/why-bg.png) right no-repeat;
  background-size: 35%;
  border-radius: 6px;
  padding-bottom: 50px;
}
/* Slider */
.slick-slide {
  margin: 0px 20px;
}
.slick-slide img {
  width: 100%;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
  height: 126px;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-slider .slick-list .slide {
  background: #e9f5fd;
  border-radius: 6px;
  padding: 15px;
}
.slick-initialized .slick-slide {
  margin: 0 7px;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  height: 126px;
}
/* solutions */
.solutions .row {
  align-items: center;
}
.solutions .row img {
  width: 100%;
  display: block;
}
/* clients */
.clients .card {
  border: 0;
  text-align: center;
  background-color: #e9f5fd;
}
/* cta */

@media (min-width: 1200px) {
  .cta .h1,
  .cta h1 {
    font-size: 4rem !important;
  }
  .cta .h1,
  .cta h1 span {
    color: #2aa4fb;
  }
}
/* innerHeader */
.innerHeader {
  background: url(../src/img/innerHeader.png) left no-repeat;
  background-size: cover;
  padding: 0;
}
.container-fluid {
  background: linear-gradient(180deg, #043056 1.92%, rgba(0, 0, 0, 0) 99.03%);
  padding: 130px 15px 70px 15px;
}
.innerHeader h1 {
  color: #fff;
  text-align: center;
  margin-bottom: 0;
}
/* contact-form-main */
.contact-form-main .row{
  align-items: center;
}
.contact-form-main ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.contact-form-main ul li{
  display: flex;
  margin-bottom: 15px;
}
.contact-form-main ul li .icon{
  margin-right: 10px;
  margin-top: 5px;
  color: #2aa4fb;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background-color: #2AA4FB !important;
    color: #fff !important;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
  background-color: #2AA4FB !important;
    color: #fff !important;
}
.nav-tabs .nav-item .nav-link, .nav-tabs .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
video{
  width: 100%;
}
.contactForm p {
  font-size: 0.75rem;
  margin-bottom: 0;
}
/* counter-display */
.counter-display section:nth-child(odd){
  background-color: #ffffff;
}
.counter-display section:nth-child(even){
  background-color: #ecf5ff;
}
.counter-display ul{
  list-style-type: none;
}
/* howQmaticwork */
.howQmaticwork .row{
  
}
/* counterGuide */
.counterGuide p{
  font-size: 20px;
  line-height: 24px;
}
.counterGuide p span.text-primary{
  font-weight: bold;
}
.counterGuide p{
  font-size: 20px;
  line-height: 24px;
}
.counterGuide h3 span{
  color: #2AA4FB;
}

.browser-select{
  position: relative;
}
.browser-select .chromeicon{
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 1;
  width: 80px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
}
/* download-btn-group */
.download-btn-group .btn{
  margin-right: 10px;
}
.download-btn-group .btn:last-child{
  margin-right: 0px;
}

.downloads-section .card{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
.downloads-section .btn{
  margin: 5px 5px;
}